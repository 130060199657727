import React, { useState, useRef } from "react";
import { Button, Form, Input, Alert } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { sendOTP } from "./../../store/api/sendOTP/index";
import { verifyOTP } from "../../store/api/verifyOTP";

export default function QRCodeForm() {
  const [isOtpVisible, setIsOtpVisible] = useState(false);
  const [timer, setTimer] = useState(30);
  const [otpId, setOtpId] = useState(null);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("info");
  const [isOtpButtonDisabled, setIsOtpButtonDisabled] = useState(false);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);

  const timerRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.sendOTPReducer);
  const { isVerifyingLoading } = useSelector((state) => state.verifyOTPReducer);

  const [form] = Form.useForm();

  const showAlert = (message, type) => {
    setAlertMessage(message);
    setAlertType(type);
    setAlertVisible(true);

    setTimeout(
      () => {
        setAlertVisible(false);
      },
      type === "error" ? 3000 : 2000
    );
  };

  const onSubmit = async (values) => {
    dispatch(sendOTP({ ...values, source: "Web" }))
      .unwrap()
      .then((response) => {
        if (response.Status) {
          setOtpId(response.Data.otpId);
          showAlert("OTP sent successfully!", "success");
          setIsOtpVisible(true);
          startOtpTimer();
        } else {
          showAlert(response.Message, "error");
          setIsSubmitButtonDisabled(true);
        }
      });
  };

  const onOtpSubmit = async (values) => {
    setIsOtpButtonDisabled(true);
    dispatch(
      verifyOTP({ otpCode: values.otpCode, otpId, source: "Web", eventId: "6" })
    )
      .unwrap()
      .then((response) => {
        if (response.Status) {
          showAlert("OTP verified successfully!", "success");
          if (response.Data.ePassLink) {
            navigate('/event-registration/'+1)
          } else {
            navigate("/");
          }

          if (timerRef.current) {
            clearInterval(timerRef.current);
          }
        } else {
          showAlert(response.Message, "error");
        }
      })
      .catch((_error) => {
        showAlert("Failed to verify OTP. Please try again.", "error");
      });
  };

  const startOtpTimer = () => {
    let countdown = 30;
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    timerRef.current = setInterval(() => {
      countdown -= 1;
      setTimer(countdown);
      if (countdown === 0) {
        clearInterval(timerRef.current);
      }
    }, 1000);
  };

  const resendOtp = async () => {
    const values = form.getFieldsValue();
    dispatch(sendOTP({ ...values, source: "Web" }))
      .unwrap()
      .then((response) => {
        if (response.Status) {
          setOtpId(response.Data.otpId);
          showAlert("OTP resent successfully!", "success");
          setTimer(30);
          startOtpTimer();
        } else {
          showAlert(response.Message, "error");
        }
      });
  };

  const formatTimer = (time) => {
    return String(time).padStart(2, "0");
  };

  const handleContactNumberChange = () => {
    setIsSubmitButtonDisabled(false);
    if (isOtpVisible) {
      setIsOtpVisible(false);
      setAlertVisible(false);
      setTimer(30);
      form.resetFields(["otpCode"]);
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    }
  };

  const handleOtpChange = () => {
    setIsOtpButtonDisabled(false);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      name="contact-verification-form"
      onFinish={isOtpVisible ? onOtpSubmit : onSubmit}
    >
      <Form.Item
        name="mobileNumber"
        rules={[
          {
            required: true,
            message: "Please input your contact number",
          },
          {
            pattern: /^\d{10}$/,
            message: "Please enter a valid 10-digit contact number",
          },
        ]}
      >
        <Input
          placeholder="Contact Number"
          type="tel"
          maxLength={10}
          onChange={handleContactNumberChange}
        />
      </Form.Item>

      {/* OTP Section */}
      {isOtpVisible && (
        <>
          <Form.Item
            name="otpCode"
            rules={[
              {
                required: true,
                message: "Please input the OTP",
              },
              {
                pattern: /^\d{6}$/,
                message: "Please enter a valid 6-digit OTP",
              },
            ]}
          >
            <Input
              placeholder="Enter OTP"
              type="tel"
              maxLength={6}
              onChange={handleOtpChange}
            />
          </Form.Item>

          {/* Timer or Resend OTP */}
          {timer > 0 ? (
            <p className="site-description-item-profile-p-label mt-0 mb-0">
              Retry in 00:{formatTimer(timer)} sec
            </p>
          ) : (
            <p className="site-description-item-profile-p-label mt-0 mb-0">
              <span
                style={{ color: "#ff7f00", cursor: "pointer" }}
                onClick={resendOtp}
              >
                Resend OTP
              </span>
            </p>
          )}

          {alertVisible && (
            <Alert
              message={alertMessage}
              type={alertType}
              showIcon
              afterClose={() => setAlertVisible(false)}
              style={{
                fontSize: "8px",
                padding: "4px 8px",
                borderRadius: "4px",
              }}
            />
          )}
          <Form.Item className="pt-2">
            <Button
              type="primary"
              htmlType="submit"
              block
              loading={isVerifyingLoading}
              disabled={isOtpButtonDisabled}
            >
              Verify OTP
            </Button>
          </Form.Item>
        </>
      )}
      {!isOtpVisible && alertVisible ? (
        <Alert
          message={alertMessage}
          type={alertType}
          showIcon
          afterClose={() => setAlertVisible(false)}
          style={{
            fontSize: "8px",
            padding: "4px 8px",
            borderRadius: "4px",
          }}
        />
      ) : null}

      {!isOtpVisible && (
        <Form.Item className="pt-2">
          <Button
            type="primary"
            htmlType="submit"
            block
            loading={isLoading}
            disabled={isSubmitButtonDisabled}
          >
            Submit
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}
