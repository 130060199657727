import React from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { QRCodeSVG } from 'qrcode.react';

const SuccessPage = () => {
  const { id } = useParams(); // Retrieve the ID from the route
  const googlePayLink = `upi://pay?pa=harsh.fruitwala6579@okicici&pn=Harsh Fruitwala&am=500&cu=INR&tn=Oliv Kite Festival Registration`;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        padding: '24px',
        backgroundImage: 'url(https://cluboliv.com/images/slider/club-oliv-night.webp)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        backgroundBlendMode: 'overlay',
      }}
    >
      <div
        style={{
          width: '100%',
          maxWidth: '480px',
          background: 'rgba(255, 255, 255, 0.85)',
          padding: '16px',
          borderRadius: '8px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          backdropFilter: 'blur(8px)',
          textAlign: 'center',
        }}
      >
        {/* Success Icon */}
        <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: '48px', marginBottom: '16px' }} />

        {/* Success Title */}
        <Typography.Title level={3} style={{ marginBottom: '16px' }}>
          Registration Successful!
        </Typography.Title>

        {/* Registration Details */}
        <Typography.Paragraph>
          We have received your registration ID: <strong>{id}</strong> and will verify it soon.
        </Typography.Paragraph>

        {/* Payment Instructions */}
        <Typography.Title level={4} style={{ marginBottom: '16px' }}>
          Pay the amount of <strong>INR 500</strong> via Google Pay to <strong>+91xxxxxxxx90</strong> and share the payment screenshot on <strong>+91xxxxxxxx90</strong>.
        </Typography.Title>

        {/* UPI Link */}
        <a
          href={googlePayLink}
          style={{
            display: 'inline-block',
            marginTop: '16px',
            padding: '10px 20px',
            background: '#0f9d58',
            color: '#fff',
            borderRadius: '4px',
            textDecoration: 'none',
            fontWeight: 'bold',
          }}
        >
          Pay Now via Google Pay
        </a>

        {/* QR Code */}
        <Typography.Title level={5} style={{ marginTop: '16px', marginBottom: '8px' }}>
          Or scan this QR code:
        </Typography.Title>
        <QRCodeSVG
        value={googlePayLink}
        size={256}
        level="H"
        includeMargin={true}
        />


        {/* Instruction */}
        <Typography.Paragraph style={{ marginTop: '16px', fontSize: '14px', color: '#555' }}>
          Please open this page on your mobile device or scan the QR code with any UPI-enabled app to complete the payment.
        </Typography.Paragraph>
      </div>
    </div>
  );
};

export default SuccessPage;
