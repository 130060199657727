import React, { useState } from "react";
import { Form, Input, Button, Space, Checkbox, Typography } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const EventRegistration = () => {
  const [form] = Form.useForm();
  const [totalCost, setTotalCost] = useState(0);
  const [validationError, setValidationError] = useState("");

  const nevigate = useNavigate();

  const predefinedMembers = [
    { id: 1, name: "John Doe", contact: "1234567890" },
    { id: 2, name: "Jane Smith", contact: "0987654321" },
  ];

  const MEMBER_COST = 500;
  const GUEST_COST = 700;

  const calculateTotalCost = (values) => {
    const membersCount = (values.members || []).filter(
      (checked) => checked
    ).length;
    const guestsCount = (values.guests || []).length;
    setTotalCost(membersCount * MEMBER_COST + guestsCount * GUEST_COST);
  };

  const onValuesChange = (changedValues, allValues) => {
    setValidationError(""); // Clear error on change
    calculateTotalCost(allValues);
  };

  const onFinish = (values) => {
    const membersChecked = (values.members || []).filter(Boolean).length;
    const guestsCount = (values.guests || []).length;

    if (membersChecked === 0 && guestsCount === 0) {
      setValidationError("At least one member or one guest is required");
      return;
    }

    setValidationError(""); // Clear any previous error

    const visitingMembers = predefinedMembers.filter(
      (_, index) => values.members?.[index]
    );
    const guests = values.guests || [];
    console.log("Visiting Members:", visitingMembers);
    console.log("Guests:", guests);
    console.log("Total Cost:", totalCost);
    nevigate("/event-registration-success/" + 1);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        padding: "24px",
        backgroundImage:
          "url(https://cluboliv.com/images/slider/club-oliv-night.webp)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundColor: "rgba(0, 0, 0, 0.7)", // Black backdrop
        backgroundBlendMode: "overlay", // Blend the black backdrop with the image
      }}
    >
      <div
        style={{
          width: "100%",
          maxWidth: "480px",
          background: "rgba(255, 255, 255, 0.85)",
          padding: "16px",
          borderRadius: "8px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          backdropFilter: "blur(8px)", // For a glass-like effect
        }}
      >
        {/* Logo */}
        <div style={{ textAlign: "center", marginBottom: "24px" }}>
          <img
            src="/img/logo-dark.png"
            alt="Logo"
            style={{ maxWidth: "120px", maxHeight: "50px" }}
          />
        </div>
        <Typography.Title
          level={4}
          style={{ textAlign: "center", marginBottom: "16px" }}
        >
          Important Notes
        </Typography.Title>
        <ul style={{ marginBottom: "16px", paddingLeft: "20px" }}>
          <li>Members Entry Cost: ₹{MEMBER_COST}</li>
          <li>Members Entry Fee Refund on Venue</li>
          <li>Guests Entry Cost: ₹{GUEST_COST}</li>
        </ul>
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          onValuesChange={onValuesChange}
        >
          {/* Members Section */}
          <h4>Members</h4>
          {predefinedMembers.map((member, index) => (
            <Space
              key={member.id}
              align="baseline"
              style={{ display: "flex", marginBottom: 8, width: "100%" }}
            >
              <Form.Item
                name={["members", index]}
                valuePropName="checked"
                noStyle
              >
                <Checkbox />
              </Form.Item>
              <Input value={member.name} readOnly style={{ flex: 1 }} />
              <Input value={member.contact} readOnly style={{ flex: 1 }} />
            </Space>
          ))}

          {/* Guests Section */}
          <h4>Guests</h4>
          <Form.List
            name="guests"
            rules={[
              {
                validator: async (_, guests) => {
                  const membersChecked =
                    form.getFieldValue("members")?.filter(Boolean).length || 0;
                  const guestsCount = guests?.length || 0;

                  if (membersChecked === 0 && guestsCount === 0) {
                    setValidationError("At least one member or one guest is required");
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    align="baseline"
                    style={{ display: "flex", marginBottom: 8, width: "100%" }}
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "name"]}
                      style={{ flex: 1 }}
                      rules={[
                        { required: true, message: "Please enter the name" },
                      ]}
                    >
                      <Input placeholder="Person Name" />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, "contact"]}
                      style={{ flex: 1 }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter the contact number",
                        },
                        {
                          pattern: /^\d+$/,
                          message: "Contact must be a number",
                        },
                      ]}
                    >
                      <Input placeholder="Contact Number" />
                    </Form.Item>

                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item style={{ textAlign: "center" }}>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add Person
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          {/* Validation Error */}
          {validationError && (
            <Typography.Text
              type="danger"
              style={{
                display: "block",
                marginTop: "8px",
                textAlign: "center",
              }}
            >
              {validationError}
            </Typography.Text>
          )}
          {/* Total Cost */}
          <Typography.Title
            level={4}
            style={{ textAlign: "center", margin: "16px 0" }}
          >
            Total Cost: ₹{totalCost}
          </Typography.Title>

          <Form.Item style={{ marginTop: "16px" }}>
            <Button type="primary" htmlType="submit" block>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default EventRegistration;
